<template>
  <div class="message">
    <p class="light-alien-green">
      keyword not found.
    </p>
    <p class="light-alien-green">
      for a list of available commands, type <strong>help</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage'
}
</script>

<style>

</style>