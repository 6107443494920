
import { defineComponent, computed } from 'vue';
import routeData from '@/data';

export default defineComponent({
  name: 'CommandMessage',
  setup() {
    const commands = Object.keys(routeData);

    const commandsPretty = computed((): string => {
      return commands.join(", ");
    })

    return {
      commandsPretty,
    };
  }
});
