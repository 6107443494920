
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: ['inputMessage'],
  setup() {
    const user = ref('client');
    const location = ref('wet.work:');
    const preInput = ref('$~');

    return {
      user,
      location,
      preInput
    };
  }
})
