
import { defineComponent, ref, onMounted, computed, SetupContext } from "vue";
import routeData from "@/data";

export default defineComponent({
  props: [],
  setup(_props, context: SetupContext) {
    const user = ref("client");
    const location = ref("wet.work:");
    const preInput = ref("$~");
    const terminalInput = ref<HTMLInputElement>();
    const command = ref("");

    onMounted(() => {
      terminalInput.value!.focus();
    });

    const doesCommandExist = computed(() => {
      return Object.prototype.hasOwnProperty.call(routeData, command.value);
    });

    const showMessage = () => {
      if (!doesCommandExist.value) {
        context.emit("error", command.value);
      } else {
        context.emit("sendCommand", command.value);
      }
      command.value = "";
    };

    return {
      user,
      command,
      location,
      preInput,
      showMessage,
      terminalInput,
      doesCommandExist,
    };
  },
});
