
import { defineComponent } from "vue";

interface Show {
  date: string;
  location: string;
  venue: string;
  cost: string;
  time: string;
  bands: string;
}

export default defineComponent({
  setup() {
    const upcomingShows: Show[] = [
      {
        date: "January 15, 2025",
        location: "Richmond, VA",
        venue: "The Camel",
        cost: "TBD",
        time: "TBD",
        bands: "Dog Lips // Wetwork // Sun Years // More TBD"
      }
    ];
    return { upcomingShows };
  },
});
