
import {
  defineComponent,
  triggerRef,
  ref,
  onUpdated,
} from "vue";
import BannerMessage from "@/components/messages/Banner.vue";
import CachedInput from "@/components/messages/CachedInput.vue";
import MessageHistory from "@/interfaces/messageHistory.interface";
import TerminalInput from "@/components/TerminalInput.vue";
import routeData from "@/data";
import ErrorMessage from "@/components/messages/Error.vue";
import { messages } from "@/composables/messages";

export default defineComponent({
  name: "TerminalLanding",
  components: {
    BannerMessage,
    CachedInput,
    TerminalInput,
  },
  setup() {
    const terminalInner = ref<HTMLDivElement>();

    onUpdated(() => {
      scrollToBottom();
    });

    const buildComponentInstructions = (command: string): MessageHistory => {
      return {
        cachedInput: CachedInput,
        component: routeData[command].component,
        input: command,
      };
    };

    const buildErrorComponentInstructions = (
      command: string
    ): MessageHistory => {
      return {
        cachedInput: CachedInput,
        component: ErrorMessage,
        input: command,
      };
    };

    const loadComponent = (input: string) => {
      const message = buildComponentInstructions(input);
      messages.value.push(message);
      triggerRef(messages);
    };

    const loadDefaultErrComponent = (input: string) => {
      const message = buildErrorComponentInstructions(input);
      messages.value.push(message);
      triggerRef(messages);
    };

    const scrollToBottom = () => {
      terminalInner.value!.scrollTop = terminalInner.value!.scrollHeight;
    };

    return {
      messages,
      CachedInput,
      terminalInner,
      loadComponent,
      loadDefaultErrComponent,
    };
  },
});
