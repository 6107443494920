
import { defineComponent } from 'vue';
import Terminal from '@/components/Landing.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    Terminal,
  },
});
