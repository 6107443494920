<script setup lang="ts">

</script>

<template>
  <div class="d__flex flex-dir__col my-3">
    <a target="_blank" class="white mb-3" href="https://www.instagram.com/wetworkva/">instagram</a>
    <a target="_blank" class="white mb-3" href="https://wetworkva.bandcamp.com/album/wetwork">bandcamp</a>
    <a class="white" href="mailto:wetworkva@gmail.com">wetworkva@gmail.com</a>
  </div>
</template>

<style scoped>
</style>