import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message d__flex flex-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AudioPlayer = _resolveComponent("AudioPlayer")!

  return (_ctx.audioSources)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.audioSources, (source, i) => {
          return (_openBlock(), _createBlock(_component_AudioPlayer, {
            key: i,
            source: source,
            currentlyPlaying: _ctx.currentlyPlaying,
            onIsPlaying: _ctx.togglePlayback
          }, null, 8, ["source", "currentlyPlaying", "onIsPlaying"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}