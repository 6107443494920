
import { defineComponent, computed, ref, watch } from 'vue';
import type { AudioSource } from "@/components/messages/Audio.vue";

export default defineComponent({
  name: 'AudioPlayer',
  props: {
    currentlyPlaying: {
      type: String,
      required: true
    },
    source: {
      type: Object as () => AudioSource,
      required: true,
    }
  },
  setup(props) {
    const isPlaying = ref(false);
    const isPaused = ref(true);
    const audio = ref<HTMLAudioElement>();
    const rangeData = ref(0);
    const songTitle = ref<HTMLParagraphElement | null>(null);
    const titleContainer = ref<HTMLDivElement | null>(null);
    const marqueeStyle = ref<{ animationDuration: string; animationName: string } | null>(null);

    const extension = computed(() => {
      return props.source.title.split(".").pop()?.toUpperCase() || "";
    });

    const updateSlider = () => {
      rangeData.value = Math.round((audio.value!.currentTime / audio.value!.duration) * 100);
    };

    const updateAudioTimeStamp = () => {
      const currentTime = audio.value!.duration * (rangeData.value / 100);
      const roundedFrame = Math.round(currentTime * 10) / 10;
      audio.value!.currentTime = roundedFrame;
    };

    const togglePlayback = () => {
      if (isPaused.value) {
        audio.value!.play();
      } else {
        audio.value!.pause();
      }
      isPlaying.value = !isPlaying.value;
      isPaused.value = !isPaused.value;
    };

    watch(() => props.currentlyPlaying, (newVal) => {
      if (newVal !== props.source.source) {
        audio.value?.pause();
        isPlaying.value = false;
        isPaused.value = true;
      }
    });

    return {
      audio,
      extension,
      isPlaying,
      isPaused,
      togglePlayback,
      updateSlider,
      rangeData,
      updateAudioTimeStamp,
      marqueeStyle,
      songTitle,
      titleContainer,
    };
  }
});
